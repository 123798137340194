import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { dlPush } from '../../events'
import { Section, SectionInner } from "../section"


export const SectionMainPrizes = styled(Section).attrs({
  bgAlt: true
})`
  padding-bottom: 112px;
  padding-top: 80px;

  @media (max-width: 375px) {
    padding-bottom: 70px;
    padding-top: 56px;
  }

  h2 {
    margin-bottom: 48px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0;

    @media (max-width: 375px) {
      margin-bottom: 20px;
    }
  }

  ul {
    list-style-display: none;
    padding: 0;

    li{
      display: inline-block;
      margin-left: 16px;
      margin-right: 16px;
      max-width: 290px;
      width: 33%;

      span{
        display: block;
        margin-top: 16px;
      }
    }
  }
`


const CardTitle = styled.h5`
  ${props => props.small ? `
    color: ${props.theme.textSoftColor};
  ` : `
    color: ${props.h === 1 ? props.theme.textMutedColor : props.theme.textColor};
  `}

  font-weight: bold;
  font-size: ${props => props.theme.fonts.desktop.h3};
  margin: ${props => props.h === 1 ? 0 : '0 0 16px 0'};

  @media (max-width: 863px) {
    br {
      display: none;
    }
  }
`


const CardInner = styled.div`
  padding: ${props => props.small ? `16px 24px` : `24px 32px 32px 32px`};
  text-align: left;

  @media (max-width: 499px) {
    padding: 20px;
  }

  & > div {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin: ${props => props.small ? `9px 0 12px 0` : `0 0 12px 0`};
    overflow: hidden;
    transition: ease .5s all;

    ${props => props.small ? props.isExpanded ? `max-height: 1000px` : `max-height: 0px` : props.isExpanded ? `max-height: 1000px` : `max-height: 52px`};

    p {
      margin: 0;

      ${props => props.small ? `color: ${props.theme.textMutedColor};` : ``}

      strong {
        font-weight: 600;
      }
    }
  }

  button {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: ${props => props.theme.greenColor};
    cursor: pointer;
    padding: 0;
    outline: none;
    text-transform: uppercase;
    user-select: none;

    ${props => props.small ? `
      display: block;
      margin: 0 auto;
      text-align: center;
    ` : ``}
  }
`


const CardWrapper= styled.div`
  background-color: ${props => props.theme.backgroundColor};
  border-radius: 5px;
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
  display: inline-block;
  margin: 32px;
  max-width: ${props => props.small ? `368px` : `480px`};
  overflow: hidden;
  width: 50%;
  vertical-align: top;

  @media (max-width: 499px) {
    ${props => !props.small ? `
      border-radius: 0;
      margin: 20px 0;
      max-width: none;
      width: 100%;
    ` : ``}

    ${props => props.small ? `
      margin: 20px;
      max-width: none;
      width: 100%;
      width: calc(100% - 40px);
    ` : ``}
  }

  @media (min-width: 500px) and (max-width: 727px) {
    width: 75%;
  }

  .gatsby-image-wrapper {
    @media (max-width: 989px) {
      max-width: 100%;
    }
  }
`


const Text = ({ text, isExpanded, clamp }) => {
  const divRef = useRef(null)
  const pRef = useRef(null)

  useEffect(() => {
    if (!clamp) return

    let p = pRef.current
    if (isExpanded) {
      p.textContent = text
    } else {
      setTimeout(() => {
        let containerHeight = divRef.current.clientHeight
        while (p.offsetHeight > containerHeight) {
          p.textContent = p.textContent.replace(/\W*\s(\S)*$/, '...');
        }
      }, 550)
    }
  }, [ isExpanded, clamp, text ])

  return <div ref={divRef}>
    <p
      ref={pRef}
      dangerouslySetInnerHTML={{ __html: text}} />
  </div>
}


export const Card = ({ image, title, subtitle, text, small, partnerId }) => {
  const [ isExpanded, setIsExpanded ] = useState(false)
  const handleExpandButtonClick = () => {
    setIsExpanded(!isExpanded)

    if (!isExpanded) {
      dlPush({
        event: 'read_more.clicked',
        partnerId: partnerId,
      })
    }
  }

  return <CardWrapper small={small}>
    <Img
      fluid={image}
      alt={title} />
    <CardInner
        isExpanded={isExpanded}
        small={small}
    >
      <CardTitle
        h={1}
        small={small}
        dangerouslySetInnerHTML={{__html: title}} />
      {subtitle && <CardTitle h={2}>{subtitle}</CardTitle>}

      <Text
        clamp={!small}
        text={text}
        isExpanded={isExpanded} />
      <button onClick={handleExpandButtonClick.bind(partnerId)}>{isExpanded ? `Zeige weniger` : `Zeige mehr`}</button>
    </CardInner>
  </CardWrapper>
}

export default () => {
  const data = useStaticQuery(graphql`query {
    Prize1Image: file(relativePath: { eq: "adventkalender/main-prizes/01_Family_LP_4_2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 480, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize2Image: file(relativePath: { eq: "adventkalender/main-prizes/02_Love_LP_2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 480, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize3Image: file(relativePath: { eq: "adventkalender/main-prizes/03_Relax_LP_2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 480, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize4Image: file(relativePath: { eq: "adventkalender/main-prizes/04_Travel_LP_2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 480, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`)
  const cards = [
    {
      image: data.Prize1Image.childImageSharp.fluid,
      title: "2. Advent",
      subtitle: "Etwas mehr für die Familienabende",
      text: "Das ultimative Survival-Kit für den gelungenen Abend mit den Lieben. Zu viel familiäre Nähe wird schnell anstrengend. Sponsored by Teufel gibt's die Komplettlösung: die schlanke Soundbar Cinebar Lux liefert starken Surround-Sound für mitreißende Filmabende. Dank der vier integrierten passiven Bassmembranen muss kein externer Subwoofer gestellt werden, die Soundbar passt einfach aufs Sideboard oder an die Wand. Du gewinnst außerdem den passenden Flatscreen und 100 € Guthaben für Netflix. Damit auch garantiert keiner mault, sorgen wir für frisches Popcorn aus der neuen Retro-Maschine und die XXL Kuscheldecke für die ganze Bagage.",
      partnerId: 'paket 1'
    },
    {
      image: data.Prize2Image.childImageSharp.fluid,
      title: "3. Advent",
      subtitle: "Etwas mehr für die Romantiker",
      text: "Hier knistert es heftig unterm Tannenbaum: Ein refurbed™ iPhone X (Spacegrau | 64GB) mit original Panzerglas™ und passender Handyhülle lässt dich an keinem Abend mehr im Stich. Ob in passender Gesellschaft oder zum Tindern – das Romantiker-Paket garantiert dir: Kein Abend mehr alleine. Und ans nächste Date haben wir auch gedacht: es gibt das volle Programm mit Rosen, Schokofondue und in deinem Namen gepflanzten Baum, der wächst wie eure Liebe!",
      partnerId: 'paket 2'
    },
    {
      image: data.Prize3Image.childImageSharp.fluid,
      title: "4. Advent",
      subtitle: "Etwas mehr für die Gestressten",
      text: "Stressige Vorweihnachtszeit?! Da hilft nur noch eins: Abschalten, Seele baumeln lassen und Kraft tanken. Am liebsten alles in einem! Thermenhotel DAS SONNREICH**** sponsert das komplette Wellness-Paket. Du & deine Begleitung schlafen 2 Nächte im Premium Deluxe Zimmer inkl. Halbpension, Minibar & Spazugang. Außerdem dabei ist der Eintritt in die Therme Loipersdorf nebst einer SONNREICH Badetasche mit Bademänteln, -schuhen sowie weiteren Überraschungen.",
      partnerId: 'paket 3'
    },
    {
      image: data.Prize4Image.childImageSharp.fluid,
      title: "Heiligabend",
      subtitle: "Etwas mehr für Miss Tropicana 2019",
      text: "Urlaubsreif, urlaubsreifer, du im Vorweihnachtswahnsinn mit akuten Fluchttendenzen? mjam schickt dich in die Sonne oder wohin auch immer dich dein Fernweh treibt. Gewinn einen 3.000 € TUI-Gutschein und entspann dich lieber am Pool. Natürlich dürfen Sonnenbrille, schwimmende Pizza und ordentlich Sonnencreme für dein perfektes Beach-Outfit nicht fehlen.",
      partnerId: 'paket 4'
    },
  ]
  return <SectionMainPrizes>
    <SectionInner>
      <h2>Darf es etwas mehr sein? Gewinne eines der schmecktakulären Hauptpreis-Pakete:</h2>
      {cards.map((card, index) => <Card
        key={`main-prizes-card${index}`}
        image={card.image}
        title={card.title}
        subtitle={card.subtitle}
        text={card.text}
        partnerId={card.partnerId}
      />)}
    </SectionInner>
  </SectionMainPrizes>
}