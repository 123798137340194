import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SnowStorm from 'react-snowstorm'
import styled from "styled-components"

import Form from './form'
import { Section, SectionInner } from '../section'


const HeaderWrapper = styled(Section)`
  background-color: ${props => props.theme.greenColor};
  color: #fff;
  overflow: hidden;

  &:after {
    content: '';
    clear: both;
    display: block;

    @media (max-width: 375px) {
      background-image: url(${require('../../images/adventkalender/mobile-cutoff.svg')});
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      height: 34px;
    }
  }

  ${SectionInner} {
    position: relative;

    & > div {
      left: 0;
      position: absolute;
      top: 45px;
      text-align: left;

      @media (max-width: 915px) {
        padding: 25px 20px 80px 20px;
        position: static;
      }

      @media (max-width: 1344px) {
        left: 20px;
        z-index: 200;
      }

      form {
        position: relative;

        p {
          font-weight: 600;

          @media (max-width: 399px) {
            display: none;
          }
        }
      }

      h1 {
        margin-bottom: 100px;
        margin-top: 42px;

        @media (max-width: 399px) {
          margin-bottom: 48px;
        }
      }
    }
  }
`


const KV = styled.div`
  font-size: 0;
  float: right;
  min-height: 540px;
  overflow: hidden;
  position: relative;
  width: 50%;
  width: calc(50% - 48px);

  @media (max-width: 915px) {
    display: none;
    min-height: none;
  }

  @media (min-width: 916px) and (max-width: 1055px) {
    width: calc(40% - 48px);
  }

  @media (min-width: 916px) and (max-width: 1439px) {
    background-color: #112d1f;
    transition: ease .1s all;
  }

  .gatsby-image-wrapper {
    height: 540px;

    @media (min-width: 916px) and (max-width: 1055px) {
      height: auto;
      left: 50%;
      position: absolute !important;
      width: 100%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 10;
    }

    @media (min-width: 1056px) and (max-width: 1439px) {
      height: 415px;
      left: 50%;
      position: absolute !important;
      width: 100%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 10;
    }
  }

  @media (min-width: 2011px) {
    .gatsby-image-wrapper {
      height: auto !important;
      width: 100% !important;
    }
  }

  &:after {
    @media (min-width: 916px) and (max-width: 1439px) {
      background-color: #fcd800;
      content: '';
      height: 50%;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
    }
  }

  &:before{
    background-image: url(${require('../../images/adventkalender/kv-back.svg')});
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 47px;
    z-index: 100;

    @media (min-width: 2020px) {
      background-size: 100% auto;
      width: 5%;
    }
  }

  .flake {
    opacity: .6 !important;
    transform: translate3d(0px, 0px, 0px) scale(5) !important;
    z-index: 50 !important;
  }
`

export default ({ header }) => {
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "adventkalender/kv@2x.png" }) {
        childImageSharp {
          fluid (maxWidth: 1920, quality: 99) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      ImageVerticalDesktop: file(relativePath: { eq: "adventkalender/kv-vertical.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 1889, quality: 99) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }`)

  const [ snow, setSnow ] = useState()
  useEffect(() => {
    if (process.browser) {
      setSnow(<SnowStorm
        targetElement="kv"
        followMouse={false}
        snowStick={false}
        animationInterval={10000}
        className="flake"
        flakesMax={32}
        flakesMaxActive={16}
        freezeOnBlur={false} />)
    }
  }, [])

  return <HeaderWrapper>
    <SectionInner>
      <div>
        <a href="/">
          <picture>
            <source media="(max-width: 399px)" srcSet={require('../../images/logo-mobile.svg')} />
            <source media="(min-width: 400px)" srcSet={require('../../images/logo.svg')} />
            <img src={require('../../images/logo.svg')} alt="mjam.net" />
          </picture>
        </a>
        {header}

        <Form title={<p>Finde die besten Restaurants in deiner Nähe</p>} />
      </div>
    </SectionInner>
    <KV id="kv">
      {snow}
      <Img fluid={[
        data.ImageVerticalDesktop.childImageSharp.fluid,
        {
          ...data.Image.childImageSharp.fluid,
          media: `(min-width: 1889px)`,
        }
      ]} />
    </KV>
  </HeaderWrapper>
}