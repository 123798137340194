import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { Section, SectionInner } from "../section"


const Section3Steps = styled(Section)`
  h2 {
    margin-bottom: 42px;
    margin-top: 80px;
  }

  ul {
    list-style-display: none;
    padding: 0 0 96px 0;

    li{
      display: inline-block;
      margin-left: 16px;
      margin-right: 16px;
      max-width: 290px;
      width: 33%;

      @media (max-width: 969px) {
        box-sizing: border-box;
        margin-bottom: 24px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 16px;
        padding-right: 16px;
        max-width: none;
        width: 100%;
      }

      span{
        display: block;
        margin-top: 16px;
      }

      .gatsby-image-wrapper {
        @media (max-width: 399px) {
          height: 90px !important;
          width: 82px !important;

          img {
            object-fit: contain !important;
            object-position: bottom center !important;
          }
        }
      }
    }
  }
`


export default () => {
  const data = useStaticQuery(graphql`
    query {
      Icon1: file(relativePath: { eq: "adventkalender/3steps/icon1.png" }) {
        childImageSharp {
          fixed (
            width: 122,
            quality: 99,
            traceSVG: { background: "#fff", color: "#003521" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      Icon2: file(relativePath: { eq: "adventkalender/3steps/icon2.png" }) {
        childImageSharp {
          fixed (
            width: 122,
            quality: 99,
            traceSVG: { background: "#fff", color: "#003521" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      Icon3: file(relativePath: { eq: "adventkalender/3steps/icon3.png" }) {
        childImageSharp {
          fixed (
            width: 122,
            quality: 99,
            traceSVG: { background: "#fff", color: "#003521" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      Icon1Mobile: file(relativePath: { eq: "adventkalender/3steps/icon1.png" }) {
        childImageSharp {
          fixed (
            width: 82,
            quality: 99,
            traceSVG: { background: "#fff", color: "#003521" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      Icon2Mobile: file(relativePath: { eq: "adventkalender/3steps/icon2.png" }) {
        childImageSharp {
          fixed (
            width: 82,
            quality: 99,
            traceSVG: { background: "#fff", color: "#003521" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      Icon3Mobile: file(relativePath: { eq: "adventkalender/3steps/icon3.png" }) {
        childImageSharp {
          fixed (
            width: 82,
            quality: 99,
            traceSVG: { background: "#fff", color: "#003521" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return <Section3Steps>
    <SectionInner>
      <h2>Bestellen & gewinnen – so geht’s:</h2>
      <ul>
        {[
          'Bis 24.12.2019 Lieblingsessen bei&nbsp;mjam bestellen.',
          'Per Klick Adventtürchen öffnen &&nbsp;Bestätigungsmail erhalten.',
          'Sofortgewinn entdecken &&nbsp;auf eins der&nbsp;4 Hauptpreis-Pakete hoffen!',
        ].map((item, index) => <li>
          <Img
            key={`3steps${index}`}
            fixed={[
              data[`Icon${index + 1}Mobile`].childImageSharp.fixed,
              {
                ...data[`Icon${index + 1}`].childImageSharp.fixed,
                media: `(min-width: 399px)`
              }
            ]}
            alt={item.replace(/&nbsp;/, ' ')} />
          <span dangerouslySetInnerHTML={{ __html: item}} />
        </li>)}
      </ul>
    </SectionInner>
  </Section3Steps>
}
