import React, { useEffect } from "react"
import styled from "styled-components"

import Footer from "../../components/footer"
import Layout from "../../components/layout"
import { Section, SectionInner } from "../../components/section"

import ThreeSteps from "../../components/adventkalender/3steps"
import Header from "../../components/adventkalender/header"
import Hero from "../../components/adventkalender/hero"
import MainPrizes from "../../components/adventkalender/main-prizes"
import SEO from "../../components/adventkalender/seo"
import SmallPrizes from "../../components/adventkalender/small-prizes"

import { dlPush } from '../../events'


const SectionTeaser = styled(Section).attrs({
  bgAlt: true
})`
  font-style: italic;
  padding-bottom: 72px;
  padding-top: 64px;

  h4{
    margin: 0;

    @media (max-width: 800px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  p{
    margin: 0 auto;
    max-width: 752px;

    @media (max-width: 800px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`


const IndexPage = ({ location }) => {
  useEffect(() => {
    dlPush({
      event: "virtualPageView",
      pageUrlPath: location.pathname,
      pageType: "home",
      referrer: window.document.referrer,
      userId: null,
      locationAddress: null,
      locationArea: null,
      locationCity: null,
      locationLat: null,
      locationLon: null,
      locationCountry: "Austria",
      eventFwfFeatures:  null,
      userLoggedIn: false
    })
  }, [])

  return <Layout>
    <SEO
      title=""
    />

    <Header
      header={<h1>Der mjam Adventkalender 2019<br/>Ho Ho Hol dir die Gewinne</h1>} />
    <SectionTeaser>
      <SectionInner>
        <h4>Essen bestellen & etwas mehr freuen!</h4>
        <p>Bestell im Dezember dein Lieblingsessen bei mjam & erhalte deinen Sofortgewinn, indem du&nbsp;auf das&nbsp;Kalendertürchen des Adventkalenders klickst – jede Bestellung gewinnt.</p>
        <p>Ebenfalls hast du jede Woche die Chance auf ein sensationelles Hauptpreis-Paket.<br/>Also auf die Plätzchen, fertig, bestellen!</p>
      </SectionInner>
    </SectionTeaser>
    <ThreeSteps />
    <MainPrizes />
    <Hero />
    <SmallPrizes />
    <Footer />

  </Layout>
}

export default IndexPage
