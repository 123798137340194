import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { Section, SectionInner } from "../section"


const Left = styled.div`
  display: inline-block;
  font-size: 0;
  width: 50%;
  vertical-align: bottom;

  @media (max-width: 669px) {
    display: none;
  }
`


const LeftPanel = styled.div`
  background-color: ${props => props.theme.greenColor};
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 50%;
  width: calc(50% - 48px);

  @media (max-width: 669px) {
    display: none;
  }
`


const Right = styled.div`
  box-sizing: border-box;
  display: inline-block;
  padding-bottom: 80px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 80px;
  text-align: left;
  width: 50%;
  vertical-align: top;

  @media (max-width: 449px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 56px;
  }

  @media (max-width: 669px) {
    width: 100%;
  }

  p{
    small{
      color: ${props => props.theme.textMutedColor};
      font-size: 16px;
      line-height: 23px;
    }
  }
`


const SectionHero = styled(Section)`
  position: relative;
`


export default () => {
  const data = useStaticQuery(graphql`
  query {
    Hero: file(relativePath: { eq: "adventkalender/hero.png" }) {
      childImageSharp {
        fixed (width: 480, quality: 99, traceSVG: { color: "#003521" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }`)
  return <SectionHero>
    <LeftPanel />
    <SectionInner>
      <Left><Img fixed={data.Hero.childImageSharp.fixed} /></Left>
      <Right>
        <h2>Jede Bestellung gewinnt: Sicher dir einen Sofortgewinn & Hauptpreis</h2>
        <p>Stille Nacht, heilige Nacht, Geschenke werden zu dir gebracht! Mit jeder Bestellung bis zum 24. Dezember 2019 erhältst du einen tollen Sofortgewinn. Einfach Lieblingsessen bei mjam bestellen, auf das Adventkalender-Türchen in der Bestätigungsmail klicken und freuen!</p>
        <p>Der mjam Weihnachtswahnsinn geht weiter: Mit deinem Klick sicherst du dir außerdem die Chance auf einen unserer drei schmecktakulären Hauptpreise.</p>
        <p><small>Detaillierte Infos zur Einlösung und der Aktion findest du in den <a href="teilnahmebedingungen/">Teilnahmebedingungen</a>.</small></p>
      </Right>
    </SectionInner>

  </SectionHero>
}