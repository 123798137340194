import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Card, SectionMainPrizes } from './main-prizes'
import { SectionInner } from "../section"


export default () => {
  const data = useStaticQuery(graphql`query {
    Prize1: file(relativePath: { eq: "adventkalender/small-prizes/DHAS-169-img-Flixbus@2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 368, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize2: file(relativePath: { eq: "adventkalender/small-prizes/DHAS-169-img-Babbel-v2@2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 368, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize3: file(relativePath: { eq: "adventkalender/small-prizes/DHAS-169-img-Mr Spex@2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 368, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize4: file(relativePath: { eq: "adventkalender/small-prizes/DHAS-169-img-Bookbeat@2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 368, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize5: file(relativePath: { eq: "adventkalender/small-prizes/DHAS-169-img-mjam@2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 368, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize6: file(relativePath: { eq: "adventkalender/small-prizes/DHAS-169-img-Teufel@2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 368, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Prize7: file(relativePath: { eq: "adventkalender/small-prizes/DHAS-169-img-J Horning@2x.png" }) {
      childImageSharp {
        fluid (maxWidth: 368, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`)
  const cards = [
    {
      image: data.Prize1.childImageSharp.fluid,
      title: "Etwas für die Heimreise<br/>&nbsp;",
      text: "Günstig, bequem und umweltfreundlich: Mit mehr als 350.000 täglichen Verbindungen in 29 Ländern zu 2.000 Reisezielen kannst Du mit FlixBus & FlixTrain ganz Europa entdecken.",
      partnerId: "flixbus",
    },
    {
      image: data.Prize2.childImageSharp.fluid,
      title: "Etwas für die Völkerverständigung",
      text: "Hast du Appetit auf eine neue Sprache? Babbel schenkt dir <strong>3 Monate gratis</strong> zu deinem 3-Monatsabo. Du erhältst Zugang zu allen Kursen der 14 angebotenen Sprachen und lernst dank praktischer Dialoge von Anfang an richtig sprechen.",
      partnerId: "babbel",
    },
    {
      image: data.Prize3.childImageSharp.fluid,
      title: "Etwas für mehr Klarheit",
      text: "Mister Spex-Adventsüberraschung: Zu Weihnachten gibt’s <strong>15 % auf unsere große Auswahl</strong> an Brillen, Sonnenbrillen und Kontaktlinsen. Beschenke dich selbst und finde jetzt dein neues Lieblingsaccessoire!",
      partnerId: "mister spex",
    },
    {
      image: data.Prize4.childImageSharp.fluid,
      title: "Etwas für Geschichtenerzähler",
      text: "BookBeat Hörbuch-Flatrate – Entfliehe den Weihnachtsvorbereitungen mit entspannten Hörbuchstunden! Jetzt kannst du unsere Hörbuch-App <strong>1 Monat lang gratis nutzen</strong> und unbegrenzt Hörbücher hören!",
      partnerId: "bookbeat",
    },
    {
      image: data.Prize5.childImageSharp.fluid,
      title: "Etwas für die Küchenprofis",
      text: "Deine vorweihnachtliche To-do-Liste ist so lang wie die Chinesische Mauer & alles, woran du denkst, ist Ente süßsauer? Wir schenken dir bis zu 3 € Rabatt und bringen dir dein Lieblingsgericht nach Hause.",
      partnerId: "mjam",
    },
    {
      image: data.Prize6.childImageSharp.fluid,
      title: "Etwas für die “Stille Nacht”",
      text: "Entdecke die riesige Auswahl an Sound-Equipment bei Teufel – Europas Nr. 1 beim Direktvertrieb von hochwertigen Audioprodukten! <strong>Spare mit mjam 10 %</strong> auf alle soundstarken Bluetooth-Speaker & Kopfhörer.",
      partnerId: "teufel",
    },
    {
      image: data.Prize7.childImageSharp.fluid,
      title: "Etwas für echte Kaffeeliebhaber",
      text: "Bestelle jetzt deinen J. Hornig Kaffeevorrat und lass dir diesen einfach & bequem vor die Haustür liefern. So wie dein Lieblingsessen. Und mit mjam gibt’s bei deiner Bestellung sogar <strong>15 % Rabatt</strong>!",
      partnerId: "hornig",
    },
  ]
  return <SectionMainPrizes>
    <SectionInner>
      <h2>Türchen öffne dich: Diese Sofortgewinne erwarten dich:</h2>
      {cards.map((card, index) => <>
        <Card
          key={`main-prizes-card${index}`}
          image={card.image}
          title={card.title}
          text={card.text}
          partnerId={card.partnerId}
          small
        />
        {index === 1 && <br/>}
        {index === 4 && <br/>}
      </>)}
    </SectionInner>
  </SectionMainPrizes>
}